import { Input } from 'antd'
import { useState } from 'react'
import ModalProduct from './ModalProduct'

interface Props {
    t: any // i18n
    setLoading: Function
    loadData: Function
    loadingCallData: any
}
const { Search } = Input

const AddProductFromLink = (props: Props) => {
    const [link, setLink] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const { t, loadData, loadingCallData } = props
    const handleSearch = (value: string) => {
        if (value) setOpenModal(true)
    }

    const handleModal = () => {
        setOpenModal(!openModal)
    }

    const handleClear = () => {
        setOpenModal(false)
        setLink('')
        loadingCallData()
    }
        
    return (
        <>
            <Search
                placeholder={`${t('cart.add_product_from_link_search')}...`}
                allowClear
                size="large"
                onChange={e => setLink(e.target.value)}
                onSearch={handleSearch}
                style={{ width: '80%', borderRadius: '12px' }}
                enterButton
                value={link}
                // ={<i className="fa-solid fa-magnifying-glass font-medium text-base"/>}
                // onClear={handleClear}
            />
            {openModal && (
                <ModalProduct
                    link={link}
                    openModal={openModal}
                    handleModal={handleModal}
                    t={t}
                    loadData={loadData}
                    handleClear={handleClear}
                />
            )}
        </>
    )
}

export default AddProductFromLink
