/* eslint-disable array-callback-return */
import React from 'react'
import { fromJS } from 'immutable'
import lodash, { uniqBy, map, isEmpty } from 'lodash'
import MainLayout from '../../components/Layout/MainLayout'
import Loading from '../../components/Loading'
import { Alert, Checkbox, Col, Empty, Modal, notification, Pagination, Popconfirm, Row, Select, Spin, Steps } from 'antd'
import * as AntdIcon from '@ant-design/icons'
import AntdIconSVG from '@ant-design/icons'

import OrderServices from '../../services/orders.service'
import OrderService from '../../services/orders.service'
import CartGroup from '../../components/Carts/CartGroup'
import RelatedService from '../../components/Carts/RelatedService'
import { LocalStore } from '../../utils/LocalStore'
import CategoriesService from '../../services/categories.service'
import { modifyArrayDataSource } from '../../utils/arrayUltils'
import { getUrlQueryString, openPopupCenter } from '../../utils/helper'
import {
    moneyCeil,
    moneyFormat,
    parseQueryStringToObject,
    quantityFormat,
    updateQueryStringParameter,
} from '../../utils/stringUtils'
import Checkout from './Checkout'
import FinishOrder from './FinishOrder'
import UserService from '../../services/user.service'
import { USER_STATUS } from '../../constants/config'
import { ReactComponent as iconBanned } from '../../resources/images/Icon-banned.svg'
import { withNamespaces } from 'react-i18next'
import ModalAddProduct from '../../components/Carts/ModalAddProduct'
import ImportExcelProducts from '../../components/Carts/ImportExcelProducts'
import { ModalEditSkuItemSalePrice } from '../../components/Carts/ModalEditSkuItemSalePrice'
import { filter, interval, of, skipWhile, switchMap, tap } from 'rxjs'
import _ from 'lodash'
import AddProductFromLink from './AddProductFromLink'

class Carts extends React.Component {
    constructor(props) {
        super(props)
        this.isUnmounted = false
        this.state = {
            targetCurrency: 'VND',
            step: '1',
            page: 1,
            pageSize: 5,
            isLoading: true,
            listCarts: [],
            checkedListPage: [],
            listCartsOrder: [],
            lastEditSalePriceSkuItem: null,
            isShowEditSalePriceSkuItemModal: false,
            editSalePriceSkuItemLoading: false,
            isDraftOrderLoanable: false,
            loansFailureList: [],
            depositTabActiveKey: '',
            isConnectedBiffin: false,
            totalLoanAmount: 0,
            feeLoansAmount: 0,
            isEnabledBiffin: false,
            markupRateViewTemplate: null,
            exchangeRateByMarketplaces: [],
            marketplaceCodes: [],
            isUpdateDraftOrder: true,
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null
        window.addEventListener('popstate', this.onPopState.bind(this))

        const productPerMerchant = LocalStore.getInstance().read('productPerMerchant') || 5
        let currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        const configGroup = lodash.get(currentLoggedUser, 'customerGroup.configGroup')
        let markupRateViewTemplate = configGroup?.markupRateViewTemplate
        if (markupRateViewTemplate) {
            try {
                markupRateViewTemplate = JSON.parse(markupRateViewTemplate)
                markupRateViewTemplate = lodash.sortBy(markupRateViewTemplate, ['price'])
            } catch (error) {
                markupRateViewTemplate = []
            }
        }
        // const marketpalaces = LocalStore.getInstance().read('marketpalaces')
        // const firstMarketplace = first(marketpalaces)

        this.setState(
            {
                isBanned: currentLoggedUser.customerStatus === USER_STATUS.BANNED,
                configGroup,
                currentLoggedUser,
                productPerMerchant,
                markupRateViewTemplate,
                isLoading: true,
            },
            () => {
                this.loadFromQueryString()
                this.getExchangeRate()
                this.getOrderServices()
                this.getGroupOrderServices()
                this.getBalanceUser()
                this.checkEnableBiffin()
            }
        )

        if (!LocalStore.getInstance().read('cartLanguage')) {
            this.setState({ cartLanguage: 'VN' }, () => {
                LocalStore.getInstance().save('cartLanguage', this.state.cartLanguage)
            })
        } else {
            this.setState({ cartLanguage: LocalStore.getInstance().read('cartLanguage') })
        }
    }

    getMarketplaceAll = async (codes = []) => {
        try {
            const promises = codes.map(code => this.getExchangeRateByMarketplace(code.toString()))
            const results = await Promise.all(promises)
            const exchangeRateByMarketplaces = results.map((result, index) => ({
                ...result,
                codeMarketplace: codes[index],
            }))
            this.setState({
                exchangeRateByMarketplaces,
            })
        } catch (err) {
            console.error('Error fetching exchange rates:', err)
        }
    }

    getExchangeRateByMarketplace = code => {
        return new Promise((resolve, reject) => {
            CategoriesService.getCurrentExchangeRateMarketplace(code, (error, response, headers) => {
                if (error) {
                    reject(error)
                } else {
                    resolve(response)
                }
            })
        })
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true
        window.removeEventListener('popstate', this.onPopState.bind(this))
    }

    checkEnableBiffin() {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        const shopkeeperAuth = lodash.get(currentLoggedUser, 'customerAuthorities.shopkeeper')
        const shopkeeperEnabled = currentProjectInfo?.tenantConfig?.externalIntegrationConfig?.shopkeeper?.enabled
        const bifinEnabledOrder = currentProjectInfo?.tenantConfig?.externalIntegrationConfig?.shopkeeper?.enabledOrder
        // const shopkeeperAuth = lodash.get(currentLoggedUser, 'customerAuthorities.shopkeeper')
        if (shopkeeperEnabled && bifinEnabledOrder && shopkeeperAuth) {
            this.setState(
                {
                    isEnabledBiffin: true,
                },
                () => {
                    this.getBiffinConnect()
                }
            )
        }
    }

    getBiffinConnect() {
        UserService.getConnectedToBiffin((err, res) => {
            if (!err) {
                if (res.token && res.tenantId) {
                    this.setState({
                        isConnectedBiffin: true,
                    })
                }
            } else {
                console.log('err')
            }
        })
    }

    /**
     * lấy số dư tài khoản của khách hàng
     */
    getBalanceUser = () => {
        UserService.getBalanceUser((err, res) => {
            if (!err) {
                this.setState({
                    balance: res ? moneyCeil(res.balance) : 0,
                    balanceObj: res || {},
                })
            }
        })
    }
    /**
     * On popstate
     */
    onPopState() {
        if (!this.isUnmounted)
            this.setState({ isLoading: true }, () => {
                this.loadFromQueryString()
            })
    }

    /**
     * Load từ QueryString (dành cho lần load đầu tiên)
     */
    loadFromQueryString = () => {
        let queryString = getUrlQueryString()
        let queryObj = parseQueryStringToObject(queryString) || {}
        this.setState(
            {
                // step: queryObj.step || '1',
                page: queryObj.page,
                pageSize: queryObj.size || 5,
            },
            () => {
                this.loadData()
                //update lại số lượng sản phẩm trong giỏ
                this.getTotalSkusInCart()
                // if (this.mainLayoutElement && this.mainLayoutElement.TotalSkusInCart) this.mainLayoutElement.TotalSkusInCart.getTotalSkusInCart()
            }
        )
    }

    /**
     * load data
     */
    loadData = cb => {
        const { page, pageSize, currentLoggedUser, markupRateViewTemplate } = this.state
        const queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=modifiedAt:desc`
        // const queryString = `?page=${page ? page - 1 : 0}&size=${pageSize}&sort=createdAt:desc`;
        this.setState({ showProgressBar: true, isLoading: true }, () => {
            OrderServices.getListCarts(currentLoggedUser.username, queryString, async (err, res, headers) => {
                if (!err) {
                    //xóa tất các item đc trọn trong trang khi load dữ liệu
                    this.onCheckAllInPage({ target: { checked: false } })
                    const uniqueMarketplaceCode = map(
                        uniqBy(res, item => item?.marketplace?.code),
                        data => data?.marketplace?.code
                    )

                    this.setState(
                        {
                            listCarts: res,
                            metadata: {
                                pageCount: headers['x-page-count'],
                                page: headers['x-page-number'],
                                size: headers['x-page-size'],
                                total: headers['x-total-count'],
                            },
                        },
                        async () => {
                            //lấy ra danh sách phí dịch vụ theo merchant
                            // res.map(x => this.getMerchantFees(x.id));
                            if (!isEmpty(markupRateViewTemplate)) await this.getMarketplaceAll(uniqueMarketplaceCode)

                            if (this.mainLayoutElement && this.mainLayoutElement.scrollLayoutElement)
                                this.mainLayoutElement.scrollLayoutElement.scrollTop = 0
                            //window.scrollTo(0, 0)
                        }
                    )
                }
                if (cb) cb()
                this.setState({ showProgressBar: false, isLoading: false })
            })
        })
    }

    onPageChanged = page => {
        this.setState({ page: page }, () => {
            let queryString = getUrlQueryString()
            queryString = updateQueryStringParameter(queryString ? '?' + queryString : '', 'page', page)
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString,
            })
            // window.scrollTo(0, 0);
        })
    }

    /**
     * lấy ra danh sách services
     */
    getOrderServices = () => {
        CategoriesService.getOrderServices((err, res, headers) => {
            if (!err) {
                // console.log('headers', headers)
                this.setState({
                    listServices: Array.isArray(res) ? res.filter(x => x.onlyStaff !== true) : [],
                    metadataListServices: {
                        pageCount: headers['x-page-count'],
                        page: headers['x-page-number'],
                        size: headers['x-page-size'],
                        total: headers['x-total-count'],
                    },
                })
            }
        })
    }

    /**
     * lấy ra danh sách nhóm services
     */
    getGroupOrderServices = () => {
        CategoriesService.getOrderServicesGroup((err, res, headers) => {
            if (!err) {
                // console.log('headers', headers)
                this.setState({
                    listGroupServices: res,
                    metadataListGroupServices: {
                        pageCount: headers['x-page-count'],
                        page: headers['x-page-number'],
                        size: headers['x-page-size'],
                        total: headers['x-total-count'],
                    },
                })
            }
        })
    }

    /**
     * lấy ra tỷ giá
     */
    getExchangeRate = () => {
        CategoriesService.getCurrentExchangeRate((err, res, header) => {
            if (!err) {
                this.setState({
                    exchangeRate: res,
                })
            }
        })
    }

    /**
     * xóa bản ghi
     * @param id
     * @param dataSource
     * @param isLastItem
     */
    deleteSkuItem = (id, dataSource, isLastItem) => {
        const { currentLoggedUser, listCarts, page } = this.state
        const { t } = this.props
        this.setState({ [`isLoading${dataSource.id}`]: true }, () => {
            OrderServices.deleteSkusItem(currentLoggedUser.username, id, (err, res) => {
                if (!err) {
                    if (listCarts.length === 1) {
                        this.onPageChanged(page > 1 ? page - 1 : 1)
                    }
                    this.loadData(() => {
                        notification['success']({
                            message: t('message.delete_success'),
                        })
                        this.setState({ [`isLoading${dataSource.id}`]: false })
                    })
                    //update lại số lượng sản phẩm trong giỏ
                    // this.mainLayoutElement.TotalSkusInCart.getTotalSkusInCart()
                    this.getTotalSkusInCart()
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                    this.setState({ [`isLoading${dataSource.id}`]: false })
                }
            })
        })
    }

    /**
     * xóa các bản ghi sku
     */
    deleteSkuItems = () => {
        const { checkedListPage, currentLoggedUser, listCarts, page } = this.state
        const { t } = this.props
        if (checkedListPage.length < 1) return

        let skus = ''
        checkedListPage.map(x => {
            skus = skus + x.id + ','
        })
        skus = skus.slice(0, -1)
        const totalSKUsInList = _.sumBy(listCarts, obj => _.sumBy(obj.products, product => _.get(product, 'skus.length', 0)))

        this.setState({ showProgressBar: true }, () => {
            OrderServices.deleteSkusItem(currentLoggedUser.username, skus, (err, res) => {
                if (!err) {
                    if (listCarts.length === 1 || totalSKUsInList === checkedListPage.length) {
                        this.onPageChanged(page > 1 ? page - 1 : 1)
                    }
                    this.loadData(() => {
                        notification['success']({
                            message: t('message.delete_success'),
                        })
                    })

                    //update lại số lượng sản phẩm trong giỏ
                    // this.mainLayoutElement.TotalSkusInCart.getTotalSkusInCart();
                    this.getTotalSkusInCart()
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
            })
        })
    }

    /**
     * xóa 1 shop
     * @param id
     */
    deleteCartItem = id => {
        const { currentLoggedUser } = this.state
        const { t } = this.props
        this.setState({ [`isLoading${id}`]: true }, () => {
            OrderServices.deleteCartItem(currentLoggedUser.username, id, (err, res) => {
                if (!err) {
                    this.onPageChanged(1)
                    this.loadData(() => {
                        notification['success']({
                            message: t('message.delete_success'),
                        })
                        this.setState({ [`isLoading${id}`]: false })
                    })
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                    this.setState({ [`isLoading${id}`]: false })
                }
            })
        })
    }

    /**
     * cập nhật ghi chú cá nhân
     * @param item
     * @param prop
     * @param idCart
     * @param cb
     */
    updateNoteSku = (item, prop, idCart, cb) => {
        let id = item.id
        let payload = {
            note: item.note || '',
            remark: item.remark || '',
        }
        const { t } = this.props
        const { currentLoggedUser } = this.state
        this.setState({ showProgressBar: true, [`isLoading${idCart}`]: true }, () => {
            OrderServices.updateSkuItem(currentLoggedUser.username, id, payload, (err, res) => {
                if (!err) {
                    // update thành công thì cập nhật lại thông tin của các SKUS cùng product
                    let { listCarts } = this.state
                    let immutableListCarts = fromJS(listCarts)
                    // tìm ra id của shop trong list
                    let indexListCart = listCarts.findIndex(x => x.id === res.id)
                    if (indexListCart >= 0) {
                        this.setState({
                            listCarts: immutableListCarts.setIn([indexListCart], fromJS(res)).toJS(),
                        })
                    }
                    if (cb) cb()
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
                this.setState({ showProgressBar: false, [`isLoading${idCart}`]: false })
            })
        })
    }

    /**
     * cập nhật ghi chú cho đơn
     * @param id
     * @param data
     * @param prop
     * @param cb
     */
    updateNoteOrder = (id, data, prop, cb) => {
        let payload = {
            [prop]: data,
        }
        const { currentLoggedUser } = this.state
        const { t } = this.props
        this.setState({ showProgressBar: true, [`isLoading${id}`]: true }, () => {
            OrderServices.updateCartItem(currentLoggedUser.username, id, payload, (err, res) => {
                if (!err) {
                    // update thành công thì cập nhật lại thông tin của các SKUS cùng product
                    let { listCarts } = this.state
                    let immutableListCarts = fromJS(listCarts)
                    // tìm ra id của shop trong list
                    let indexListCart = listCarts.findIndex(x => x.id === res.id)
                    if (indexListCart >= 0) {
                        immutableListCarts = immutableListCarts.setIn([indexListCart, 'remark'], res.remark)
                        immutableListCarts = immutableListCarts.setIn([indexListCart, 'note'], res.note)
                        this.setState({
                            listCarts: immutableListCarts.toJS(),
                        })
                    }
                    if (cb) cb()
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
                this.setState({ showProgressBar: false, [`isLoading${id}`]: false })
            })
        })
    }

    /**
     * cập nhật thông tin của sku item
     * @param id
     * @param body
     * @param idCart
     */
    updateSkuItem = (id, body, idCart) => {
        const { currentLoggedUser } = this.state
        const { t } = this.props
        this.setState({ showProgressBar: true, [`isLoading${idCart}`]: true }, () => {
            OrderServices.updateSkuItem(currentLoggedUser.username, id, body, (err, res, headers) => {
                if (!err) {
                    // update thành công thì cập nhật lại thông tin của các SKUS cùng product
                    let { listCarts } = this.state
                    let immutableListCarts = fromJS(listCarts)
                    // tìm ra id của shop trong list
                    let indexListCart = listCarts.findIndex(x => x.id === res.id)
                    if (indexListCart >= 0) {
                        this.setState({ listCarts: immutableListCarts.setIn([indexListCart], fromJS(res)).toJS() })
                    }
                    //update lại số lượng sản phẩm trong giỏ
                    // this.mainLayoutElement.TotalSkusInCart.getTotalSkusInCart();
                    this.getTotalSkusInCart()
                    // lấy lại phí dịch vụ của merchant

                    if (this[`relateService${idCart}`] && this[`relateService${idCart}`].state.showListFees === true) {
                        this[`relateService${idCart}`].getMerchantFees(idCart)
                    }
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
                this.setState({ showProgressBar: false, [`isLoading${idCart}`]: false })
            })
        })
    }

    /**
     * thêm services vào cart
     * @param id
     * @param services
     */
    addServiceToOrder = (id, services) => {
        const { t } = this.props
        let body = []
        services.map(x => {
            body.push(x.code)
        })
        const { currentLoggedUser } = this.state

        this.setState({ [`isLoading${id}`]: true }, () => {
            OrderServices.addServicesToCart(currentLoggedUser.username, id, body, (err, res, headers) => {
                if (!err) {
                    // console.log('addServiceToOrder', res);
                    // update thành công thì cập nhật lại thông tin của merchant
                    let { listCarts } = this.state
                    let immutableListCarts = fromJS(listCarts)
                    // tìm ra id của shop trong list
                    let indexListCart = listCarts.findIndex(x => x.id === id)
                    if (indexListCart >= 0) {
                        immutableListCarts = immutableListCarts.setIn([indexListCart, 'services'], fromJS(res))
                        this.setState(
                            {
                                [`isLoading${id}`]: false,
                                listCarts: immutableListCarts.toJS(),
                            },
                            () => {
                                if (this[`relateService${id}`] && this[`relateService${id}`].state.showListFees === true) {
                                    this[`relateService${id}`].getMerchantFees(id)
                                }
                                notification['success']({
                                    message: t('message.save_success'),
                                })
                            }
                        )
                    }
                } else {
                    if (
                        err.title === 'order_service_requires' ||
                        err.title === 'order_service_require_groups' ||
                        err.title === 'order_service_excludes' ||
                        err.title === 'order_service_exclude_groups'
                    ) {
                        notification['error']({
                            message: t('error.service_change'),
                        })
                    } else if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                    this.setState({ [`isLoading${id}`]: false })
                }
            })
        })
    }

    /**
     * tạo mới 1 draft order
     */
    createDraftOrder = id => {
        const { listCarts, disabledAll, currentLoggedUser, isConnectedBiffin, isEnabledBiffin } = this.state
        const { t } = this.props
        if (disabledAll) return

        let skus = []

        if (listCarts.find(x => x.id === id)) {
            if (!this.state[`checkedList${id}`] || this.state[`checkedList${id}`].length === 0) {
                modifyArrayDataSource(listCarts.find(x => x.id === id)).map(x => {
                    skus.push(x.id)
                })
            } else if (Array.isArray(this.state[`checkedList${id}`])) {
                this.state[`checkedList${id}`].map(x => {
                    skus.push(x.id)
                })
            }
        }

        let body = {
            skus,
        }

        this.setState({ [`isLoading${id}`]: true, disabledAll: true }, () => {
            OrderServices.createDraftOrder(currentLoggedUser.username, body, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            draftOrder: res,
                        },
                        () => {
                            this.nextStep()
                            if (isConnectedBiffin && isEnabledBiffin) {
                                this.checkShoppingCartLoanable(res)
                            }
                        }
                    )
                } else {
                    if (err.title === 'other_marketplace_disabled') {
                        notification['error']({
                            message: t('message.other_marketplace_disabled'),
                        })
                    } else if (err.title === 'order_service_domestic_invalid') {
                        notification['error']({
                            message: t('message.order_service_domestic_invalid'),
                        })
                    } else if (err.title === 'order_service_international_invalid') {
                        notification['error']({
                            message: t('message.order_service_international_invalid'),
                        })
                    } else if (err.title === 'receipt_address_required') {
                        notification['error']({
                            message: t('message.receipt_address_required'),
                        })
                    } else if (err.statusCode !== 'Network fail') {
                        notification['error']({
                            message: t(err.message),
                        })
                    }
                }
                this.setState({ [`isLoading${id}`]: false, disabledAll: false })
            })
        })
    }

    /**
     * update draft order
     * @param draftOrder
     * @param body
     * @param cb
     */
    updateDraftOrder = (draftOrder, body, cb) => {
        const { currentLoggedUser } = this.state
        const { t } = this.props
        this.setState({ isUpdateDraftOrder: true }, () => {
            OrderServices.updateDraftOrder(currentLoggedUser.username, draftOrder.id, body, (err, res, headers) => {
                if (!err) {
                    this.setState(
                        {
                            draftOrder: res,
                        },
                        () => {
                            if (cb) cb()
                        }
                    )
                } else {
                    if (err.title === 'order_service_domestic_invalid') {
                        notification['error']({
                            message: t('message.address_order_service_domestic_invalid'),
                        })
                    } else if (err.title === 'order_service_international_invalid') {
                        notification['error']({
                            message: t('message.address_order_service_international_invalid'),
                        })
                    } else if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
                this.setState({ isUpdateDraftOrder: false })
            })
        })
    }

    /**
     * update draft order
     * @param draftOrder
     * @param body
     */
    checkShoppingCartLoanable = draftOrder => {
        const { t } = this.props
        this.setState({ isLoadingCheckShoppingCartLoanable: true }, () => {
            OrderServices.checkShoppingCartLoanable({ draftOrderId: draftOrder.id }, (err, res, headers) => {
                if (!err) {
                    this.setState({ isLoadingCheckShoppingCartLoanable: false })
                    if (res.status === 'success') {
                        this.setState({
                            isDraftOrderLoanable: true,
                            feeLoansAmount: lodash.sumBy(res.loans, i => i.feeAmount),
                            totalLoanAmount: lodash.sumBy(res.loans, i => i.loanAmount),
                        })
                    } else {
                        this.setState({
                            isDraftOrderLoanable: false,
                            loansFailureList: res.loans,
                            totalLoanAmount: 0,
                            feeLoansAmount: 0,
                        })
                    }
                } else {
                    this.setState({
                        isDraftOrderLoanable: false,
                        totalLoanAmount: 0,
                        feeLoansAmount: 0,
                        isLoadingCheckShoppingCartLoanable: false,
                    })
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
                this.setState({ isLoadingCheckShoppingCartLoanable: false })
            })
        })
    }

    updateShoppingCartLoan = (draftOrder, loanAmount, loanPercentage) => {
        const { t } = this.props
        this.setState({ isLoadingCheckShoppingCartLoanable: true }, () => {
            OrderServices.checkShoppingCartLoanable(
                { draftOrderId: draftOrder.id, loanAmount, loanPercentage },
                (err, res, headers) => {
                    if (err) {
                        if (err.statusCode !== 'Network fail')
                            notification['error']({
                                message: t(err.message),
                            })
                    }
                    this.setState({ isLoadingCheckShoppingCartLoanable: false })
                }
            )
        })
    }

    /**
     * tạo draft order của nhiều shop
     */
    createDraftOrderMultipleShop = () => {
        const { listCarts, checkedListPage, disabledAll, currentLoggedUser, isConnectedBiffin, isEnabledBiffin } = this.state
        const { t } = this.props
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}
        const maxSkus = tenantConfig.maxSkuByMerchant
        if (disabledAll) return

        // console.log('checkedListPage',checkedListPage);
        //add service từng shop nếu có item đc chọn
        let arrayShop = []
        if (maxSkus !== null && maxSkus !== undefined) {
            arrayShop = listCarts.filter(
                x =>
                    checkedListPage.filter(y => y.dataSourceId === x.id).length > 0 &&
                    checkedListPage.filter(y => y.dataSourceId === x.id).length <= maxSkus
            )
        } else {
            arrayShop = listCarts.filter(x => checkedListPage.filter(y => y.dataSourceId === x.id).length > 0)
        }

        // console.log('arrayShop',arrayShop);
        // return
        let checkLastApiSuccess = arrayShop.length
        let objStateLoading = {}
        let objStateLoadingFalse = {}
        let arrayChecked = []
        arrayShop.map(x => {
            this.validateOrder(x, () => {
                checkLastApiSuccess = checkLastApiSuccess - 1
                // call api nếu tất cả các api add service đều thành công

                objStateLoading[`isLoading${x.id}`] = true
                objStateLoadingFalse[`isLoading${x.id}`] = false
                arrayChecked = [...arrayChecked, ...checkedListPage.filter(y => y.dataSourceId === x.id)]
                if (checkLastApiSuccess === 0) {
                    let skus = []
                    arrayChecked.map(x => {
                        skus.push(x.id)
                    })
                    let body = {
                        skus,
                    }
                    this.setState({ ...objStateLoading, disabledAll: true }, () => {
                        OrderServices.createDraftOrder(currentLoggedUser.username, body, (err, res, headers) => {
                            if (!err) {
                                this.setState(
                                    {
                                        draftOrder: res,
                                    },
                                    () => {
                                        this.nextStep()
                                        if (isConnectedBiffin && isEnabledBiffin) {
                                            this.checkShoppingCartLoanable(res)
                                        }
                                    }
                                )
                            } else {
                                if (err.title === 'other_marketplace_disabled') {
                                    notification['error']({
                                        message: t('message.other_marketplace_disabled'),
                                    })
                                } else if (err.title === 'order_service_domestic_invalid') {
                                    notification['error']({
                                        message: t('message.order_service_domestic_invalid'),
                                    })
                                } else if (err.title === 'order_service_international_invalid') {
                                    notification['error']({
                                        message: t('message.order_service_international_invalid'),
                                    })
                                } else if (err.statusCode !== 'Network fail') {
                                    notification['error']({
                                        message: t(err.message),
                                    })
                                }
                            }

                            this.setState({ ...objStateLoadingFalse, disabledAll: false })
                        })
                    })
                }
            })
        })
    }

    /**
     * Thêm 1 sản phẩm vào wishlist
     * @param id
     * @param cb
     */
    addItemWishlist = (id, cb) => {
        const { t } = this.props
        this.setState({ showProgressBar: true }, () => {
            UserService.addItemWishlist('cart', id, (err, res, headers) => {
                if (!err) {
                    if (cb) cb()
                    notification['success']({
                        message: t('message.successfully_saved_product'),
                    })
                } else {
                    notification['error']({
                        message: t('message.fail_saved_product'),
                    })
                }
                this.setState({ showProgressBar: false })
            })
        })
    }

    /**
     * validate order
     * @param item
     * @param cb
     * @returns {boolean}
     */
    validateOrder = (item, cb) => {
        let isError = false
        let dataList = this[`cartGroup${item.id}`].state.dataList
        if (Array.isArray(dataList) && dataList.find(x => x.error)) {
            // console.log('vào lỗi cart')
            isError = true
        }

        if (item?.services?.length === 0) {
            notification.error({ description: 'Vui lòng chọn dịch vụ' })
            return
        }

        isError = this.validateServices(item, true)

        if (!isError) {
            // nếu ko có lỗi gọi callback
            if (cb) cb()
            // this.addServiceToOrder(item.id, checkedList, cb)
        }
        // debugger;
        return isError
    }

    /**
     * validate để add service vào merchant
     * @param item là merchant
     */
    validateServices = (item, isOrderValidate) => {
        const { listGroupServices = [], listServices = [] } = this.state
        const { t } = this.props
        let isError = false
        // lấy ra danh sách checkedList của OrderServiceGroup component
        let checkedList = this[`relateService${item.id}`].orderServiceGroup.state.checkedList

        let isSubmit = false

        //kiểm tra xem hai mảng có phần tử khác nhau nào ko
        checkedList.map(x => {
            if (item.services.filter(y => y.code === x.code).length === 0) {
                isSubmit = true
            }
        })
        if (checkedList.length !== item.services.length) {
            isSubmit = true
        }
        // kiểm tra nếu 2 mảng bằng về độ dài thì kiểm tra xem có phần tử nào khác nhau ko
        if (!isSubmit) {
            return
        }

        let listServiceTemp = lodash.uniq(
            listServices?.map(listService => listService?.serviceGroup?.code).filter(serviceGroup => serviceGroup)
        )
        listGroupServices
            .filter(i => {
                return listServiceTemp.includes(i.code) && i.required
            })
            .map(x => {
                let index = listGroupServices.findIndex(y => y.id === x.id)

                if (checkedList.filter(y => y.serviceGroup && y.serviceGroup.id === x.id).length === 0) {
                    isError = true
                    if (index >= 0) {
                        listGroupServices[index].error = true
                    }
                } else {
                    if (index >= 0) {
                        listGroupServices[index].error = false
                    }
                }
                this.setState({ listGroupServices })
            })

        let totalRequired = 0
        checkedList.map((x, index) => {
            totalRequired = 0
            if (Array.isArray(x.requires) && x.requires.length > 0) {
                x.requires.map(y => {
                    if (!!checkedList.find(j => j.code === y)) {
                        totalRequired = totalRequired + 1
                    }
                })
                if (totalRequired < x.requires.length) {
                    isError = true
                    checkedList[index].requiresMessage = t('error.requiresMessage', {
                        service: checkedList[index].name,
                        services: x.requires.map((y, i) => {
                            return listServices.find(a => a.code === y)
                                ? listServices.find(a => a.code === y).name + (i < x.requires.length - 1 ? ', ' : '')
                                : ''
                        }),
                    })
                } else {
                    checkedList[index].requiresMessage = ''
                }
            }

            totalRequired = 0
            if (Array.isArray(x.requireGroups) && x.requireGroups.length > 0) {
                x.requireGroups.map(y => {
                    if (!!checkedList.find(j => j.serviceGroup && j.serviceGroup.code === y)) {
                        totalRequired = totalRequired + 1
                    }
                })
                if (totalRequired < x.requireGroups.length) {
                    isError = true
                    checkedList[index].requireGroupsMessage = t('error.requireGroupsMessage', {
                        service: checkedList[index].name,
                        serviceGroup: x.requireGroups.map((y, i) => {
                            return listGroupServices.find(a => a.code === y)
                                ? listGroupServices.find(a => a.code === y).name + (i < x.requires.length - 1 ? ', ' : '')
                                : ''
                        }),
                    })
                } else {
                    checkedList[index].requireGroupsMessage = ''
                }
            }
        })

        if (!isError) {
            // nếu ko có lỗi thì add services vào order
            if (!isOrderValidate) this.addServiceToOrder(item.id, checkedList)
        } else {
            this[`relateService${item.id}`].orderServiceGroup.setChecklist(checkedList)
        }

        return isError
    }

    onCheckServiceGroup = item => {
        const { listGroupServices } = this.state

        if (item.serviceGroup) {
            let index = listGroupServices.findIndex(y => y.id === item.serviceGroup.id)
            listGroupServices[index].error = false

            this.setState({ listGroupServices })
        }
    }

    /**
     * checkAll in page
     * @param e
     */
    onCheckAllInPage = e => {
        const { listCarts } = this.state
        let modifyArray = []
        let checkedListObj = {}
        listCarts.map(x => {
            modifyArray = [...modifyArray, ...modifyArrayDataSource(x)]
            checkedListObj[`checkedList${x.id}`] = e.target.checked ? modifyArrayDataSource(x) : []
            checkedListObj[`indeterminate${x.id}`] = false
            checkedListObj[`checkAll${x.id}`] = e.target.checked
        })

        this.setState({
            checkedListPage: e.target.checked ? modifyArray : [],
            indeterminatePage: false,
            checkAllPage: e.target.checked,
            ...checkedListObj,
        })
    }

    /**
     * item checked
     * @param item
     * @param dataSource
     */
    onItemCheck = (item, dataSource) => {
        const { listCarts } = this.state
        let checkedList = this.state[`checkedList${dataSource.id}`] || []
        let checkedListPage = this.state.checkedListPage || []
        if (checkedList.find(x => x.id === item.id)) {
            checkedList = checkedList.filter(x => x.id !== item.id)
            checkedListPage = checkedListPage.filter(x => x.id !== item.id)
        } else {
            checkedList.push(item)
            checkedListPage.push(item)
        }

        let modifyArray = []
        listCarts.map(x => {
            modifyArray = [...modifyArray, ...modifyArrayDataSource(x)]
        })

        //nếu listCheckedPage có độ dài bằng với tổng số lượng item skus trong trang thì checkAllPage = true, indeterminatePage = false

        this.setState({
            checkedListPage,
            indeterminatePage: !!checkedListPage.length && checkedListPage.length < modifyArray.length,
            checkAllPage: checkedListPage.length === modifyArray.length,
            [`checkedList${dataSource.id}`]: checkedList,
            [`indeterminate${dataSource.id}`]:
                !!checkedList.length && checkedList.length < modifyArrayDataSource(dataSource).length,
            [`checkAll${dataSource.id}`]: checkedList.length === modifyArrayDataSource(dataSource).length,
        })
    }

    /**
     * check all in cart
     * @param e
     * @param dataSource
     */
    onCheckAllItemInCart = (e, dataSource) => {
        const { checkedListPage, listCarts } = this.state
        let arrayNoneDataSource = checkedListPage.filter(x => x.dataSourceId !== dataSource.id)
        let arrayHasDataSource = [...arrayNoneDataSource, ...modifyArrayDataSource(dataSource)]
        let modifyArray = []
        listCarts.map(x => {
            modifyArray = [...modifyArray, ...modifyArrayDataSource(x)]
        })

        this.setState({
            checkedListPage: e.target.checked ? arrayHasDataSource : arrayNoneDataSource,
            indeterminatePage: e.target.checked
                ? !!arrayHasDataSource.length && arrayHasDataSource.length < modifyArray.length
                : !!arrayNoneDataSource.length && arrayNoneDataSource.length < modifyArray.length,
            checkAllPage: e.target.checked
                ? arrayHasDataSource.length === modifyArray.length
                : arrayNoneDataSource.length === modifyArray.length,
            [`checkedList${dataSource.id}`]: e.target.checked ? modifyArrayDataSource(dataSource) : [],
            [`indeterminate${dataSource.id}`]: false,
            [`checkAll${dataSource.id}`]: e.target.checked,
        })
    }

    /**
     * chuyển sang step tiếp theo
     */
    nextStep = () => {
        this.setState({
            step: Number.isFinite(parseFloat(this.state.step)) ? (parseFloat(this.state.step) + 1).toString() : '1',
        })
    }

    /**
     * lấy ra số lượng skus trong giỏ
     */
    getTotalSkusInCart = () => {
        UserService.getTotalSkusInCart((err, res) => {
            if (!err) {
                this.setState({
                    totalSkus: res,
                })
            }
        })
    }

    updateHeader = () => {
        this.getBalanceUser()
        this.getTotalSkusInCart()
    }

    getStepName = step => {
        const { t } = this.props
        switch (step) {
            case '1':
                return t('order.cart')
            case '2':
                return t('order.address_deposit')
            case '3':
                return t('order.done')
            default:
                return t('order.cart')
        }
    }

    listCartsOrder = data => {
        this.setState({ listCartsOrder: data })
    }

    /**
     * Change Language
     */
    onChangeLanguage = () => {
        const { cartLanguage } = this.state
        this.setState({ cartLanguage: cartLanguage === 'VN' ? 'CN' : 'VN' }, () => {
            LocalStore.getInstance().save('cartLanguage', this.state.cartLanguage)
        })
    }

    /**
     * Xoá all giỏ hàng
     */
    deleteAllCart = () => {
        const { t } = this.props
        this.setState({ showProgressBar: true, isLoading: true }, () => {
            OrderServices.deleteAllCart((err, res) => {
                if (!err) {
                    this.setState({ page: 1, isLoading: false }, () => {
                        this.loadData(() => {
                            notification['success']({
                                message: t('message.delete_success'),
                            })
                        })
                    })
                } else {
                    if (err.statusCode !== 'Network fail')
                        notification['error']({
                            message: t(err.message),
                        })
                }
            })
        })
    }

    handleChange = e => {
        this.setState({ pageSize: e, page: 1 }, () => {
            let queryString = getUrlQueryString()
            queryString = updateQueryStringParameter(queryString ? '?' + queryString : '', 'page', 1)
            queryString = updateQueryStringParameter(queryString, 'size', e)
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString,
            })
        })
    }

    handleChangeProductPerMerchant = e => {
        this.setState({ productPerMerchant: e }, () => {
            LocalStore.getInstance().save('productPerMerchant', e)
        })
    }

    handleEditSkuItemSalePriceVisibleChange = (skuItem, visible) => {
        this.setState({
            lastEditSalePriceSkuItem: skuItem,
            isShowEditSalePriceSkuItemModal: visible,
        })
    }

    handleEditSkuItemSalePrice = data => {
        const { currentLoggedUser } = this.state
        const { t } = this.props
        this.setState({ editSalePriceSkuItemLoading: true })

        OrderServices.updateSkuItem(currentLoggedUser.username, data.id, { bargainPrice: data.bargainPrice }, error => {
            this.setState({ editSalePriceSkuItemLoading: false })
            if (!error) {
                this.handleEditSkuItemSalePriceVisibleChange(null, false)
                this.loadFromQueryString()
                notification.success({
                    message: t('modalEditSalePrice.updateSuccess'),
                })
            } else {
                notification.error({
                    message: t(error.message),
                })
            }
        })
    }

    handleChangeDepositTab = values => {
        const { draftOrder, isConnectedBiffin, isEnabledBiffin, totalLoanAmount } = this.state
        const { key, loanAmount, loanPercentage } = values

        this.setState({ depositTabActiveKey: key })
        if (key === 'cam_do') {
            this.updateDraftOrder(
                draftOrder,
                {
                    tags: ['cam_do'],
                },
                () => {
                    if (isConnectedBiffin && isEnabledBiffin && totalLoanAmount === 0) {
                        // this.checkShoppingCartLoanable(draftOrder)
                    } else {
                        this.updateShoppingCartLoan(draftOrder, loanAmount, loanPercentage)
                    }
                }
            )
        } else {
            this.updateDraftOrder(
                draftOrder,
                {
                    tags: [],
                    depositOnDemand: loanPercentage,
                    // emdAmount: loanPercentage
                },
                () => {
                    setTimeout(() => {}, 1000)
                }
            )
        }
    }

    handleSignUpBiffin = () => {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const currentLoggedUser = LocalStore.getInstance().read('currentLoggedUser') || {}
        const username = lodash.get(currentLoggedUser, 'username')
        const email = lodash.get(currentLoggedUser, 'email')
        const fullname = lodash.get(currentLoggedUser, 'fullname')
        const phone = lodash.get(currentLoggedUser, 'phone')
        const partnerId = lodash.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.partnerId')
        const redirectUri = lodash.get(currentProjectInfo, 'tenantConfig.externalIntegrationConfig.shopkeeper.redirectUri')

        openPopupCenter(
            {
                url: OrderService.gotoBiffinUrl({
                    username,
                    partnerId,
                    redirectUri,
                    email,
                    fullname,
                    phone,
                }),
                title: 'Bifiin Authentication',
                w: 1100,
                h: 600,
            },
            currentWindow => {
                let window$ = of(currentWindow)
                let itv$ = interval(500)

                let getDraftOrder$ = of(w => {
                    this.getBiffinConnect()
                    currentWindow.close()
                })

                let job$ = itv$
                    .pipe(
                        switchMap(() => window$),
                        skipWhile(w => !w.closed),
                        filter(w => w.closed),
                        tap(() => {
                            getDraftOrder$.subscribe(processFunc => {
                                processFunc()
                                job$.unsubscribe()
                            })
                        })
                    )
                    .subscribe()
            }
        )
    }


    loadingCallData = () => {
        this.setState({ isLoading: true })
    }
    


    /**
     * render header page
     * @returns {*}
     */
    renderHeaderPage = () => {
        const { step, pageSize, productPerMerchant } = this.state
        const { t } = this.props
        const { Option } = Select
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}
        const generalConfig = tenantConfig.generalConfig || {}
        const enableGetGlobalProduct = generalConfig.enableGetGlobalProduct || false

        return (
            <div className={'pdr20 mgt20'}>
                <Row className={'mgbt50'}>
                    <Col span={12}>
                        {step !== '3' ? (
                            <div className={'titletop'}>
                                <h2 className={'mgbt25'}>
                                    {this.getStepName(step)}
                                    <span>
                                        <span className={'mgr10 mgl10'}>| </span>
                                        <span
                                            className="cursor-pointer"
                                            onClick={this.onChangeLanguage}
                                        >
                                            {t('order.change_language')}
                                        </span>
                                    </span>
                                    {step === '1' && (
                                        <Popconfirm
                                            overlayClassName={'_delete-all-in-page-popconfirm-orverlay'}
                                            className={`_delete-all-in-page-popconfirm cursor-pointer`}
                                            title={t('message.delete_confirm')}
                                            onConfirm={this.deleteAllCart}
                                            okText={t('button.yes')}
                                            cancelText={t('button.no')}
                                        >
                                            <span>
                                                <span className={'mgr10 mgl10'}>| </span>
                                                <span className="cursor-pointer">{t('cart.delete_all_purchasers')}</span>
                                            </span>
                                        </Popconfirm>
                                    )}
                                    {generalConfig.otherMarketplaceEnabled && (
                                        <ModalAddProduct
                                            {...this.props}
                                            loadFromQueryString={this.loadFromQueryString}
                                        />
                                    )}
                                    {generalConfig.otherMarketplaceEnabled && <ImportExcelProducts {...this.props} />}
                                </h2>
                                <Row className={'position-re'}>
                                    {/*<Col span={12}>*/}
                                    {/*<Input placeholder={t('order.enter_seller_name')} />*/}
                                    {/*<Icon type="search" className={'wpsearch__ic position-ab txt-size-h5 txt-color-gray'}/>*/}
                                    {/*</Col>*/}
                                    <Col
                                        span={24}
                                        className="dpl-flex"
                                    >
                                        <div className="pdr15">
                                            <label className="pdr10">{t('order.cart_size_change')}</label>
                                            <Select
                                                value={pageSize}
                                                style={{ width: 80 }}
                                                onChange={e => this.handleChange(e)}
                                            >
                                                <Option value={5}>5</Option>
                                                <Option value={10}>10</Option>
                                                <Option value={15}>15</Option>
                                                <Option value={20}>20</Option>
                                            </Select>
                                        </div>
                                        <div>
                                            <label className="pdr10">
                                                {t('config_group.number_products')}/{t('cartCheckout.seller')}
                                            </label>
                                            <Select
                                                value={productPerMerchant}
                                                style={{ width: 80 }}
                                                onChange={e => this.handleChangeProductPerMerchant(e)}
                                            >
                                                <Option value={5}>5</Option>
                                                <Option value={10}>10</Option>
                                                <Option value={15}>15</Option>
                                                <Option value={20}>20</Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                    </Col>
                    <Col
                        span={12}
                        // align={'center'}
                    >
                        <div className={'steps mb-10'}>
                            <Steps
                                size="small"
                                current={Number.isFinite(parseFloat(step)) ? parseFloat(step) - 1 : 0}
                            >
                                <Steps.Step title={t('order.cart')} />
                                <Steps.Step title={t('order.address_deposit')} />
                                <Steps.Step title={t('order.done')} />
                            </Steps>
                        </div>
                        {enableGetGlobalProduct && (
                            <AddProductFromLink
                                {...this.props}
                                loadData={this.loadData}
                                loadingCallData={this.loadingCallData}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    /**
     * render carts
     */
    renderCarts = () => {
        const { listCarts, metadata, isLoading, pageSize } = this.state
        const { t } = this.props
        return (
            <div className={'cartscontainer pdbt80 pdr20'}>
                {Array.isArray(listCarts) && listCarts.length > 0 ? (
                    listCarts.map(this.renderCartGroup)
                ) : isLoading ? (
                    <Loading />
                ) : (
                    <Empty
                        image={require('../../resources/images/empty-image.png')}
                        description={t('message.empty')}
                    />
                )}
                <Pagination
                    className={'text-center _pagination-carts'}
                    hideOnSinglePage={true}
                    showQuickJumper
                    current={metadata ? parseFloat(metadata.page) + 1 : 1}
                    total={metadata ? parseFloat(metadata.total) : 1}
                    pageSize={metadata ? parseFloat(metadata.size) : pageSize}
                    onChange={page => this.onPageChanged(page)}
                />
            </div>
        )
    }

    /**
     * render cart item
     * @param item
     * @param index
     */
    renderCartGroup = (item, index) => {
        const {
            productPerMerchant = 5,
            currentLoggedUser,
            markupRateViewTemplate,
            configGroup,
            exchangeRateByMarketplaces,
        } = this.state
        // console.log("renderCartGroup item : ", item);
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const tenantConfig = currentProjectInfo.tenantConfig || {}
        const maxSkus = tenantConfig.maxSkuByMerchant
        return (
            <Spin
                key={index}
                wrapperClassName="_merchant-item"
                spinning={this.state[`isLoading${item.id}`] === true || this.state.isLoading === true}
                indicator={
                    <AntdIcon.Loading3QuartersOutlined
                        type="loading"
                        style={{ fontSize: 24 }}
                        spin
                    />
                }
            >
                <Row className="">
                    <Col
                        span={17}
                        className={'listcartsipad listcards pdr15'}
                    >
                        <CartGroup
                            t={this.props.t}
                            ref={ref => (this[`cartGroup${item.id}`] = ref)}
                            exchangeRate={this.state.exchangeRate}
                            index={index}
                            key={index}
                            dataSource={item}
                            exchangeRateByMarketplaces={exchangeRateByMarketplaces}
                            onItemCheck={this.onItemCheck}
                            onCheckAll={this.onCheckAllItemInCart}
                            checkedList={this.state[`checkedList${item.id}`]}
                            indeterminate={this.state[`indeterminate${item.id}`]}
                            checkAll={this.state[`checkAll${item.id}`]}
                            //action
                            deleteCartItem={this.deleteCartItem}
                            deleteSkuItem={this.deleteSkuItem}
                            updateSkuItem={this.updateSkuItem}
                            updateNoteSku={this.updateNoteSku}
                            addItemWishlist={this.addItemWishlist}
                            isLoadingDisable={this.state[`isLoading${item.id}`]}
                            //language
                            cartLanguage={this.state.cartLanguage}
                            productPerMerchant={productPerMerchant}
                            currentLoggedUser={currentLoggedUser}
                            markupRateViewTemplate={markupRateViewTemplate}
                            configGroup={configGroup}
                            onEditSkuItemSalePriceVisibleChange={this.handleEditSkuItemSalePriceVisibleChange}
                        />
                    </Col>
                    <Col
                        span={7}
                        className={'pdl15'}
                    >
                        <RelatedService
                            t={this.props.t}
                            ref={ref => (this[`relateService${item.id}`] = ref)}
                            orderServiceGroups={this.state.listGroupServices}
                            orderServices={this.state.listServices}
                            validateServices={this.validateServices}
                            onCheckServiceGroup={this.onCheckServiceGroup}
                            exchangeRate={this.state.exchangeRate}
                            dataSource={item}
                            validateOrder={() => this.validateOrder(item, () => this.createDraftOrder(item.id))}
                            updateNoteOrder={this.updateNoteOrder}
                            isLoadingDisable={
                                this.state[`isLoading${item.id}`] ||
                                this.state.disabledAll ||
                                (Array.isArray(this.state[`checkedList${item.id}`]) &&
                                    maxSkus &&
                                    this.state[`checkedList${item.id}`].length > maxSkus)
                            }
                            showNotiMaxSkus={
                                (!this.state[`checkedList${item.id}`] || this.state[`checkedList${item.id}`].length === 0) &&
                                modifyArrayDataSource(item).length > maxSkus
                            }
                            isBanned={this.state.isBanned}
                        />
                    </Col>
                </Row>
            </Spin>
        )
    }

    getTotalForeignAmount = () => {
        const { checkedListPage, listCarts } = this.state
        let totalAmount = {}

        if (checkedListPage.length) {
            for (let item of checkedListPage) {
                if (!totalAmount.hasOwnProperty(item.currency)) {
                    totalAmount[item.currency] = 0
                }

                for (let cartItem of listCarts) {
                    if (cartItem.id === item.dataSourceId) {
                        for (let prod of cartItem.products) {
                            for (let iSku of prod.skus) {
                                if (iSku.code === item.code) {
                                    totalAmount[item.currency] += iSku.totalAmount
                                }
                            }
                        }
                    }
                }
            }
        } else {
            for (let cartItem of listCarts) {
                for (let prod of cartItem.products) {
                    for (let iSku of prod.skus) {
                        if (!totalAmount.hasOwnProperty(iSku.currency)) {
                            totalAmount[iSku.currency] = 0
                        }
                        totalAmount[iSku.currency] += iSku.totalAmount
                    }
                }
            }
        }

        totalAmount = lodash.map(totalAmount, (v, k) => {
            return {
                currency: k,
                totalAmount: v,
            }
        })

        return totalAmount
    }

    renderFixedRow = suspensionSchedule => {
        const { listCarts = [], isBanned, checkedListPage } = this.state
        const { t } = this.props
        let listSkus = []
        let totalQuantity = 0
        let totalProductPrice = 0
        let totalShop = listCarts.length
        listCarts.map(merchant => {
            totalProductPrice = totalProductPrice + merchant.exchangedTotalValue

            listSkus = modifyArrayDataSource(merchant)
            listSkus.map(item => {
                totalQuantity = totalQuantity + item.quantity
            })
        })

        if (checkedListPage.length) {
            totalProductPrice = 0
            totalQuantity = 0
            totalShop = lodash.uniq(checkedListPage.map(it => it.dataSourceId)).length
            for (let item of checkedListPage) {
                for (let cartItem of listCarts) {
                    if (cartItem.id === item.dataSourceId) {
                        for (let prod of cartItem.products) {
                            for (let iSku of prod.skus) {
                                if (iSku.code === item.code) {
                                    totalProductPrice = totalProductPrice + iSku.exchangedTotalAmount
                                    totalQuantity = totalQuantity + iSku.quantity
                                }
                            }
                        }
                    }
                }
            }
        }

        let disabled =
            suspensionSchedule &&
            Array.isArray(suspensionSchedule.appliedFor) &&
            !!suspensionSchedule.appliedFor.find(x => x === 'ALL' || x === 'ORDER')

        const totalForeignAmount = this.getTotalForeignAmount()

        return (
            <div className={`orderfixed pdl15 dpl-flex align-items-center justify-content-between`}>
                <div className={'orderfixed__left dpl-flex justify-content-between line-height134'}>
                    <div className={'dpl-flex pdt3'}>
                        <Checkbox
                            className={'_check-all-item-in-page'}
                            indeterminate={this.state.indeterminatePage}
                            onChange={e => this.onCheckAllInPage(e)}
                            checked={this.state.checkAllPage}
                        >
                            <span className={'txt-color-gray txt-size-h7 robotoregular'}>{t('cart.choose_all')}</span>
                            <span className={'txt-color-gray txt-size-h7 robotoregular pdl15'}>|</span>
                        </Checkbox>
                        {this.state.checkedListPage.length < 1 ? (
                            <span className={'_delete-all-in-page txt-color-gray txt-size-h7 robotoregular'}>
                                {t('cart.delete_all')}
                            </span>
                        ) : (
                            <Popconfirm
                                overlayClassName={'_delete-all-in-page-popconfirm-orverlay'}
                                className={`_delete-all-in-page-popconfirm cursor-pointer`}
                                title={t('message.delete_confirm')}
                                onConfirm={this.deleteSkuItems}
                                okText={t('button.yes')}
                                cancelText={t('button.no')}
                            >
                                <span className={'_delete-all-in-page cursor-pointer txt-color-gray txt-size-h7 robotoregular'}>
                                    {t('cart.delete_all')}
                                </span>
                            </Popconfirm>
                        )}
                    </div>
                    <div className={'_cartRow-total-amount-box pdr30'}>
                        <span className={'txt-color-gray txt-size-h7 robotoregular mgr5'}>{t('cart.foreignAmount')}: </span>
                        {totalForeignAmount.length > 0 ? (
                            <>
                                {totalForeignAmount.map((it, i) => (
                                    <span
                                        key={i}
                                        className={`_cartRow-total-amount txt-size-h1 txt-color-red robotobold pdt3 mgr10 cart-foreign-amount-footer-item ${
                                            i === totalForeignAmount.length - 1 ? 'last' : ''
                                        }`}
                                    >
                                        {moneyFormat(it.totalAmount, it.currency)}
                                    </span>
                                ))}
                            </>
                        ) : (
                            <span
                                className={`_cartRow-total-amount txt-size-h1 txt-color-red robotobold pdt3 mgr10 cart-foreign-amount-footer-item last`}
                            >
                                ---
                            </span>
                        )}
                        <span className={'txt-color-gray txt-size-h7 robotoregular'}>{t('cart.sum')} </span>
                        <span className={'_cartRow-total-amount txt-size-h1 txt-color-red robotobold pdt3'}>
                            {moneyFormat(moneyCeil(totalProductPrice))}
                        </span>{' '}
                        (<span className={'_cartRow-total-shop txt-size-h7 txt-color-black robotoregular'}>{totalShop}</span>{' '}
                        {t('cart.shop')} / <span className={'_cartRow-total-product'}>{quantityFormat(totalQuantity)}</span>{' '}
                        {t('cart.product')})
                    </div>
                </div>
                <div className={'orderfixed__right txt-center'}>
                    <div
                        onClick={() => {
                            if (disabled) return
                            if (isBanned) {
                                Modal.info({
                                    className: 'modal-user-banned',
                                    maskClosable: true,
                                    title: t('order.notification'),
                                    content: (
                                        <div>
                                            <p className="txt-size-h7 mgbt5">{t('cart.order_function_locked_account')}</p>
                                            <p className="txt-size-h7 mgbt5">{t('cart.contact_consultant')}</p>
                                        </div>
                                    ),
                                    // okText: ''
                                })
                            } else {
                                this.createDraftOrderMultipleShop()
                            }
                        }}
                        className={`_cartRow-btn-order-all ${!disabled ? 'cursor-pointer' : ''} pdl15 pdr15`}
                    >
                        <span className={'txt-size-h8 txt-color-black robotobold'}>{t('cart.order_all_chosen_product')}</span>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {
            step,
            draftOrder,
            totalLoanAmount,
            feeLoansAmount,
            listCartsOrder,
            isUpdateDraftOrder,
            isBanned,
            balance,
            balanceObj = {},
            totalSkus,
            isDraftOrderLoanable,
            loansFailureList,
            isConnectedBiffin,
            isEnabledBiffin,
            lastEditSalePriceSkuItem,
            isShowEditSalePriceSkuItemModal,
            editSalePriceSkuItemLoading,
            depositTabActiveKey,
            isLoadingCheckShoppingCartLoanable,
        } = this.state
        const { t } = this.props
        let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo')
        let suspensionSchedule = currentProjectInfo.suspensionSchedule
        let disabled =
            suspensionSchedule &&
            Array.isArray(suspensionSchedule.appliedFor) &&
            !!suspensionSchedule.appliedFor.find(x => x === 'ALL' || x === 'ORDER')

        return (
            <MainLayout
                ref={ref => (this.mainLayoutElement = ref)}
                {...this.props}
                noFooter={true}
                balance={balance}
                totalSkus={totalSkus}
                showProgressBar={this.state.showProgressBar}
                headerTitle={t('cart.cart')}
                title={this.getStepName(step)}
            >
                <div className={'container-common position-re'}>
                    {isBanned && (
                        <div className="pdr20 mgbt10">
                            <Alert
                                className="custom-alert"
                                icon={
                                    <AntdIconSVG
                                        component={iconBanned}
                                        style={{ fontSize: 20 }}
                                    />
                                }
                                message={
                                    <div className="txt-size-h7 txt-color-red">{t('cart.order_function_blocked_account')}</div>
                                }
                                description={<div className="txt-size-h8 txt-color-black">{t('cart.contact_consultant')}</div>}
                                type="error"
                                showIcon
                                closable
                            />
                        </div>
                    )}
                    {disabled && (
                        <div className="pdr20 mgbt10">
                            <Alert
                                className="custom-alert-suspension-schedule white-space-pre-wrap break-word"
                                message={<div className="txt-size-h7">{t('cart.suspension_notify')}</div>}
                                description={
                                    <div
                                        className="txt-size-h8 txt-color-black"
                                        dangerouslySetInnerHTML={{
                                            __html: suspensionSchedule ? suspensionSchedule.description : '',
                                        }}
                                    />
                                }
                                type="warning"
                                showIcon
                                closable
                            />
                        </div>
                    )}
                    {this.renderHeaderPage()}

                    {step === '1' && this.renderCarts()}
                    {step === '1' && this.renderFixedRow(suspensionSchedule)}

                    {step === '2' && (
                        <Checkout
                            t={t}
                            updateLoadingStatus={value => this.setState({ isUpdateDraftOrder: value })}
                            updateDraftOrder={this.updateDraftOrder}
                            isUpdateDraftOrder={isUpdateDraftOrder}
                            balance={this.state.balance}
                            balanceObj={balanceObj}
                            nextStep={this.nextStep}
                            updateHeader={this.updateHeader}
                            draftOrder={draftOrder}
                            listCartsOrder={this.listCartsOrder}
                            depositTabActiveKey={depositTabActiveKey}
                            isDraftOrderLoanable={isDraftOrderLoanable}
                            loansFailureList={loansFailureList}
                            totalLoanAmount={totalLoanAmount}
                            feeLoansAmount={feeLoansAmount}
                            getBiffinConnect={this.getBiffinConnect}
                            isConnectedBiffin={isConnectedBiffin}
                            isEnabledBiffin={isEnabledBiffin}
                            //language
                            mainLayoutElement={this.mainLayoutElement}
                            cartLanguage={this.state.cartLanguage}
                            onChangeDepositTab={this.handleChangeDepositTab}
                            onSignUpBiffin={this.handleSignUpBiffin}
                            isLoadingCheckShoppingCartLoanable={isLoadingCheckShoppingCartLoanable}
                        />
                    )}

                    {step === '3' && (
                        <FinishOrder
                            t={t}
                            listCarts={listCartsOrder}
                            //action
                        />
                    )}
                </div>

                {isShowEditSalePriceSkuItemModal && (
                    <ModalEditSkuItemSalePrice
                        t={t}
                        skuItem={lastEditSalePriceSkuItem}
                        loading={editSalePriceSkuItemLoading}
                        onSubmit={this.handleEditSkuItemSalePrice}
                        onCancel={() => this.handleEditSkuItemSalePriceVisibleChange(null, false)}
                    />
                )}
            </MainLayout>
        )
    }
}

export default withNamespaces()(Carts)
