import { LocalStore } from "../LocalStore";
import { getQueryString } from "./index";
import callApi from "../fetchApi";
import { parseErrors } from "../error/parseError";
import lang from "../../resources/localization/Localization";
// import * as appJson from '../../app.json'

// console.log('aaa', appJson)

export default class PolimClient {
    static flags = {};

    static REQUEST_MODE = {
        BODY_ONCE: 'body-once',
        TAKE_LATEST_BY_ENDPOINT: 'take-latest-endpoint',
        ALL: 'all'
    };

    static callback(cb, config, err, res, header) {

        let md5 = require("blueimp-md5");
        if (config.mode === 'take-latest-endpoint') {
            let hash = md5(config.endpoint);
            if (this.flags[hash]) {
                if (this.flags[hash] > config.timestamp) {
                    //this is not latest base on endpoint, do not callback
                    console.info('request callback dismissed');
                    return
                }
            }
            this.flags[hash] = config.timestamp;
        }

        cb(err, res, header);
    }

    static once(config, cb) {
        let md5 = require("blueimp-md5");
        if (config.mode === 'body-once') {
            let hash = md5(config.endpoint + config.body);
            if (this.flags[hash]) {
                let error = {
                    message: lang.error.once
                };
                cb(error);
                return true;
            }

            this.flags[hash] = true;
            setTimeout(() => {
                delete this.flags[hash];
            }, 10000);
        }
    }

    static request(requestConfig = {
        endpoint: '',
        queryString: {},
        method: 'GET',
        headers: {},
        file: "",
        body: '',
        keepStableUrl: false,
        notAuth: false
    }, cb) {
        requestConfig = Object.assign({
            endpoint: '',
            queryString: {},
            method: 'GET',
            headers: {},
            body: '',
            file: "",
            mode: 'take-latest-endpoint',
            timestamp: new Date()
        }, requestConfig);


        cb = cb || (() => {
        });

        let defaultLang = LocalStore.getInstance().read('currentLanguage');
        defaultLang = defaultLang ? defaultLang.code : 'en-US';

        let token = LocalStore.getInstance().read('loginSession');
        //TODO: renew token on expired

        // console.log('token', token)
        if (token && !requestConfig.notAuth) {
            requestConfig.headers = Object.assign({}, {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': defaultLang,
                'Authorization': 'Bearer ' + token.id,
                'Referer': window.location.href,
            }, requestConfig.headers);
        } else {
            requestConfig.headers = Object.assign({}, {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': defaultLang,
            }, requestConfig.headers);
        }

        // console.log('requestConfig', requestConfig);

        //add client-version & client-endpoint
        // let packageInfo = {...appJson};
        requestConfig.headers['X-Client-Version'] = "0.1.0";
        requestConfig.headers['X-Client-Endpoint'] = window.location;

        requestConfig.body = typeof requestConfig.body === 'object' ? JSON.stringify(requestConfig.body) : requestConfig.body;
        requestConfig.cache = requestConfig.cache || 'no-cache';

        // Trường hợp muốn upload file
        if (requestConfig.file) {
            requestConfig.body = requestConfig.file;
            delete requestConfig.headers['Content-Type'];
            delete requestConfig.headers['Accept'];
        }
        let url = '';
        if (Object.keys(requestConfig.queryString).length > 0) {
            url = requestConfig.endpoint + getQueryString(requestConfig.queryString);
        }
        else {
            url = requestConfig.endpoint;
        }

        try {
            if (this.once(requestConfig, cb)) {
                return;
            }
            //nếu mất mạng sẽ chửi
            if (!navigator.onLine) {
                let error = {
                    name: "Error",
                    message: "error.network"
                };
                // notification.warning({
                //     key: 'Network',
                //     message: "error.network"
                // });
                return this.callback(cb, requestConfig, error);
            }

            callApi(url, {
                method: requestConfig.method,
                headers: requestConfig.headers,
                body: requestConfig.body
            }, requestConfig.keepStableUrl).then((response) => {
                // console.log('resssss', response);
                return this.callback(cb, requestConfig, null, response.response, response.headers);
            }).catch((error) => {
                if (!error) return this.callback(cb, requestConfig, {
                    code: 500,
                    message: lang.error.oops
                });

                console.debug('error', error)

                error.code = error.code || error.statusCode || error.status || error.error;
                error.payload = parseErrors(error);
                // console.log('error-error', error, error.code);

                if (error.code === 500) {
                    error.message = 'error.oops'
                } else if (error.code === 404) {
                    error.message = 'error.error404';
                } else if (error.code === 403) {
                    error.message = 'error.error403'
                } else if (error.code === 401 || error.code === 'unauthorized' || error.statusCode === 401) {
                    error.message = 'error.error401';
                    // error.message = '';
                    LocalStore.getInstance().save('loginSession', null);
                    LocalStore.getInstance().save('redirectUrl', window.location.href);
                } else if (error.code === 'timeout') {
                    error.message = 'error.timeout';
                } else {
                    error.message = 'error.oops';
                }

                return this.callback((err, res, header) => {
                    if (typeof cb === 'function') cb(err, res, header);
                    if (error.code === 401 || error.statusCode === 401) {
                        let notRedirectLoginUrls = ['/reset-password', '/forgot-password', '/verification', '/register', 'order-tracking']
                        let wantRedirect = true
                        for (let url of notRedirectLoginUrls) {
                            if (window.location.hash.indexOf(url) >= 0) {
                                wantRedirect = false
                                break
                            }
                        }

                        if (wantRedirect) {
                            window.location = window.location.origin + '/#/login';
                        }
                    }
                }, requestConfig, error);
            });
        } catch (error) {
            if (!error) return this.callback(cb, requestConfig, {
                code: 500,
                message: 'error.oops'
            });

            error.code = error.code || error.statusCode || error.status;
            error.message = 'error.oops';
            error.payload = parseErrors(error);
            return this.callback(cb, requestConfig, error);
        }
    }
}
