import { Button, Image, Input, notification } from 'antd'
import { useState } from 'react'
import { formatPriceByUnit } from '../../../utils/stringUtils'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import AddProductCart from '../../../services/addProductCart.service'
import { extractValidUrl, Marketplace } from './handleUrl'

const Product1688 = ({ product, loadData, t, link, handleClear }: any) => {
    const [quantity, setQuantity] = useState<{ [skuId: string]: number }>({})
    const [loading, setLoading] = useState(false)
    const stepQuantity = product?.batchNumber || 1

    const handleIncrease = (skuId: string) => {
        setQuantity(prevQuantities => {
            const newQuantities = { ...prevQuantities }
            newQuantities[skuId] = (newQuantities[skuId] || 0) + stepQuantity
            return newQuantities
        })
    }

    const handleDecrease = (skuId: string) => {
        setQuantity(prevQuantities => {
            const newQuantities = { ...prevQuantities }
            newQuantities[skuId] = Math.max((newQuantities[skuId] || 0) - stepQuantity, 0)
            return newQuantities
        })
    }

    const handleInputChange = (skuId: string, value: number) => {
        setQuantity(prevQuantities => {
            const newQuantities = { ...prevQuantities }
            newQuantities[skuId] = value > 9999 ? 9999 : Math.max(value, 0)
            return newQuantities
        })
    }

    const getOriginalPrice = (priceRangeList: any, quantity: number) => {
        let originalPrice = 0
        priceRangeList?.forEach((range: any) => {
            if (range.startQuantity <= quantity) {
                originalPrice = range.price
            }
        })
        if (originalPrice === 0) {
            originalPrice = product?.productSaleInfo?.consignPrice
        }
        return originalPrice
    }

    const handleAddToCart = () => {
        let selectedItems = {}
        if (product?.type === Marketplace.Alibaba) {
            if (product?.productSkuInfos === null) {
                selectedItems = [
                    {
                        name: product.subject,
                        originalName: product.subject,
                        originalPrice: getOriginalPrice(product?.productSaleInfo?.priceRangeList, quantity[product.id]),
                        salePrice: getOriginalPrice(product?.productSaleInfo?.priceRangeList, quantity[product.id]),
                        // pricePolicy: product?.productSaleInfo?.priceRangeList,
                        pricePolicy: [],
                        currency: 'CNY',
                        merchant: {
                            id: product.offerId,
                            name: product.offerId,
                            marketplace: '1688',
                        },
                        image: product.productImage?.images[0],
                        url: link,
                        variantImage: product.productImage?.images[0],
                        variantProperties: [],
                        batchSize: 1,
                        quantity: quantity[product.id],
                        stock: 999, // api k trả về stock
                        originalId: product.offerId,
                        minQuantity: product.minOrderQuantity,
                        skuId: product.id,
                        specId: product.id,
                        brand: '',
                        totalRate: null,
                    },
                ]
            } else {
                selectedItems = product.productSkuInfos
                    .filter((sku: any) => quantity[sku.skuId] > 0) // Lọc ra những SKU có quantity > 0
                    .map((sku: any) => {
                        const url = extractValidUrl(link)
                        return {
                            name: product.subject,
                            originalName: product.subject,
                            originalPrice:
                                stepQuantity > 1
                                    ? (sku.price || +sku.consignPrice) / stepQuantity
                                    : sku.price || +sku.consignPrice,
                            salePrice:
                                stepQuantity > 1
                                    ? (sku.price || +sku.consignPrice) / stepQuantity
                                    : sku.price || +sku.consignPrice,
                            pricePolicy: [],
                            currency: 'CNY',
                            merchant: {
                                id: product.offerId,
                                name: product.offerId,
                                marketplace: '1688',
                                // url: 'https://winport.m.1688.com/page/index.html?memberId=b2b-2213355468143071ab',
                                // contacts: {
                                //     wangwang: '揭阳市辰莜电子商务有限公司',
                                // },
                            },
                            image: product.productImage?.images[0],
                            url: url,
                            variantImage: sku.skuAttributes[0]?.skuImageUrl || product.productImage?.images[0],
                            variantProperties: sku.skuAttributes.map((attr: any) => ({
                                id: attr.value,
                                originalName: attr.attributeName,
                                originalValue: attr.value,
                            })),
                            batchSize: 1,
                            quantity: quantity[sku.skuId],
                            stock: 999, // api k trả về stock
                            originalId: product.offerId,
                            minQuantity: product.minOrderQuantity,
                            skuId: sku.skuId,
                            specId: sku.specId,
                            brand: '',
                            totalRate: null,
                        }
                    })
            }
        } else if (product?.type === Marketplace.Taobao) {
            if (!product?.skus) return

            selectedItems = product.skus
                .filter((sku: any) => quantity[sku.skuId] > 0) // Lọc ra những SKU có quantity > 0
                .map((sku: any) => {
                    const url = extractValidUrl(link)
                    return {
                        name: product?.title,
                        originalName: product?.title,
                        originalPrice: sku?.salePrice / 100,
                        salePrice: sku?.salePrice / 100,
                        pricePolicy: [],
                        currency: 'CNY',
                        merchant: {
                            id: product?.merchant.id,
                            name: product?.merchant.name,
                            marketplace: 'taobao',
                            // url: 'https://winport.m.1688.com/page/index.html?memberId=b2b-2213355468143071ab',
                            // contacts: {
                            //     wangwang: '揭阳市辰莜电子商务有限公司',
                            // },
                        },
                        image: product?.productImages[0],
                        url: url,
                        variantImage: sku.images[0],
                        variantProperties: sku.variantProperties.map((attr: any) => ({
                            id: attr.id,
                            originalName: attr.originalName,
                            originalValue: attr.originalValue,
                        })),
                        batchSize: 1,
                        quantity: quantity[sku.skuId],
                        stock: product.stock, // api k trả về stock
                        originalId: product.id,
                        minQuantity: product.minOrderQuantity || 1,
                        skuId: sku.skuId,
                        specId: sku.specId,
                        brand: '',
                        totalRate: null,
                    }
                })
        }

        const cartItems = { skus: selectedItems }

        setLoading(true)
        AddProductCart.addToCart(cartItems, (res: any) => {
            if (res && res?.code !== 204) {
                notification['error']({
                    message: t('message.error'),
                })
                setLoading(false)
            } else {
                loadData(handleClear())
                setLoading(false)
                notification['success']({
                    message: t('message.success'),
                })
                AddProductCart.trackingAddToCart()
            }
        })
    }

    const calculateTotal = () => {
        const totalQuantity = Object?.values(quantity)?.reduce((acc, curr) => acc + curr, 0)
        if (product?.productSkuInfos === null) {
            return {
                totalQuantity: totalQuantity,
                totalPrice: getOriginalPrice(product?.productSaleInfo?.priceRangeList, totalQuantity) * totalQuantity,
            }
        }
        if (product?.productSkuInfos?.length > 0) {
            const selectedItems =
                product?.skus?.filter((sku: any) => quantity[sku.skuId] > 0) ||
                product?.productSkuInfos?.filter((sku: any) => quantity[sku.skuId] > 0)
            const totalPrice = selectedItems?.reduce(
                (acc: number, item: any) =>
                    acc +
                    (product?.type === Marketplace.Taobao ? parseFloat(item?.salePrice) / 100 : parseFloat(item?.consignPrice)) *
                        quantity[item.skuId],
                0
            )
            return {
                totalQuantity: totalQuantity,
                totalPrice: totalPrice / stepQuantity,
            }
        } else {
            const selectedItems =
                product?.skus?.filter((sku: any) => quantity[sku.skuId] > 0) ||
                product?.productSkuInfos?.filter((sku: any) => quantity[sku.skuId] > 0)

            const totalPrice = selectedItems?.reduce(
                (acc: number, item: any) =>
                    acc +
                    (product?.type === Marketplace.Taobao ? parseFloat(item?.salePrice) / 100 : parseFloat(item?.consignPrice)) *
                        quantity[item.skuId],
                0
            )
            return { totalQuantity, totalPrice }
        }
    }

    return (
        <div className="flex items-start justify-around space-x-4 mt-6">
            <Image
                src={product?.productImage?.images[0] || product?.productImages[0]}
                preview={false}
                className="max-w-[400px] !rounded"
            />
            <div>
                <div className="text-lg font-medium mb-3">{product?.subject || product?.title}</div>
                <div
                    className={`flex flex-col gap-2 ${
                        product?.productSkuInfos === null ? '' : 'h-[340px]'
                    } overflow-y-auto relative`}
                >
                    {product?.productSkuInfos?.map((sku: any) => {
                        return (
                            <div
                                key={sku.skuId}
                                className="flex items-center gap-5 border-b border-grey-100 py-2 px-2 cursor-pointer"
                            >
                                <div className="basis-3/5 flex items-center gap-2">
                                    {sku.skuAttributes[0].skuImageUrl && (
                                        <Image
                                            src={sku.skuAttributes[0].skuImageUrl}
                                            width={60}
                                            height={60}
                                            preview={false}
                                            className=""
                                        />
                                    )}
                                    <div className="flex flex-col gap-2 ">
                                        {sku.skuAttributes.map((attribute: any) => {
                                            return (
                                                <div
                                                    key={attribute.id}
                                                    className="flex items-center space-x-2"
                                                >
                                                    <span className="whitespace-nowrap">{attribute.attributeName}:</span>
                                                    <span className="line-clamp-1">{attribute.value}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="basis-1/5 text-center">
                                    {formatPriceByUnit(
                                        stepQuantity > 1 ? sku?.consignPrice / stepQuantity : sku?.consignPrice,
                                        'CNY'
                                    )}
                                </div>
                                <div
                                    className={
                                        'txt-secondary rounded-lg px-3 py-[1px] border border-solid border-[#C0C0C0] flex items-center basis-1/5'
                                    }
                                >
                                    <span
                                        className="px-2 py-1 hover:cursor-pointer"
                                        onClick={() => handleDecrease(sku.skuId)}
                                    >
                                        <MinusOutlined />
                                    </span>
                                    <Input
                                        value={quantity[sku.skuId] || 0}
                                        onChange={e => {
                                            if (stepQuantity > 1) {
                                                return
                                            }
                                            const value = e.target.value.replace(/[^\d]/g, '')
                                            handleInputChange(sku.skuId, +value)
                                        }}
                                        style={{ textAlign: 'center', padding: '0 10px', width: '100px' }}
                                        bordered={false}
                                        max={9999}
                                    />
                                    <span
                                        className="px-2 py-1 hover:cursor-pointer"
                                        onClick={() => handleIncrease(sku.skuId)}
                                    >
                                        <PlusOutlined />
                                    </span>
                                </div>
                            </div>
                        )
                    })}

                    {product?.skus?.map((sku: any) => {
                        return (
                            <div
                                key={sku.skuId}
                                className="flex items-center gap-5 border-b border-grey-100 py-2 px-2 cursor-pointer"
                            >
                                <div className="basis-3/5 flex items-center gap-2">
                                    {sku?.images[0] && (
                                        <Image
                                            src={sku?.images[0]}
                                            width={60}
                                            height={60}
                                            preview={false}
                                            className=""
                                        />
                                    )}
                                    <div className="flex flex-col gap-2 ">
                                        {sku.variantProperties.map((attribute: any) => {
                                            return (
                                                <div
                                                    key={attribute.id}
                                                    className="flex items-center space-x-2"
                                                >
                                                    <span className="whitespace-nowrap">{attribute.originalName}:</span>
                                                    <span className="line-clamp-1">{attribute.originalValue}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="basis-1/5 text-center">{formatPriceByUnit(sku?.salePrice / 100, 'CNY')}</div>
                                <div
                                    className={
                                        'txt-secondary rounded-lg px-3 py-[1px] border border-solid border-[#C0C0C0] flex items-center basis-1/5'
                                    }
                                >
                                    <span
                                        className="px-2 py-1 hover:cursor-pointer"
                                        onClick={() => handleDecrease(sku.skuId)}
                                    >
                                        <MinusOutlined />
                                    </span>
                                    <Input
                                        value={quantity[sku.skuId] || 0}
                                        onChange={e => {
                                            const value = e.target.value.replace(/[^\d]/g, '')
                                            handleInputChange(sku.skuId, +value)
                                        }}
                                        style={{ textAlign: 'center', padding: '0 10px', width: '100px' }}
                                        bordered={false}
                                        max={9999}
                                    />
                                    <span
                                        className="px-2 py-1 hover:cursor-pointer"
                                        onClick={() => handleIncrease(sku.skuId)}
                                    >
                                        <PlusOutlined />
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {product?.productSkuInfos === null && (
                    <>
                        <div className="flex items-center justify-between">
                            {product?.productSaleInfo?.priceRangeList?.map((item: any) => {
                                return (
                                    <div>
                                        <div>Số lượng từ: {item.startQuantity}</div>
                                        <div>Giá: {formatPriceByUnit(item?.price, 'CNY')}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="mt-6 flex items-center justify-center">
                            <div
                                className={
                                    'txt-secondary w-fit rounded-lg px-3 py-[1px] border border-solid border-[#C0C0C0] flex items-center basis-1/5'
                                }
                            >
                                <span
                                    className="px-2 py-1 hover:cursor-pointer"
                                    onClick={() => handleDecrease(product.id)}
                                >
                                    <MinusOutlined />
                                </span>
                                <Input
                                    value={quantity[product.id] || 0}
                                    onChange={e => {
                                        const value = e.target.value.replace(/[^\d]/g, '')
                                        handleInputChange(product.id, +value)
                                    }}
                                    style={{ textAlign: 'center', padding: '0 10px', width: '100px' }}
                                    bordered={false}
                                    max={9999}
                                />
                                <span
                                    className="px-2 py-1 hover:cursor-pointer"
                                    onClick={() => handleIncrease(product.id)}
                                >
                                    <PlusOutlined />
                                </span>
                            </div>
                        </div>
                    </>
                )}
                <div className="flex items-start justify-between mt-6">
                    <span className="text-base flex flex-col items-start">
                        <span>Tổng số lượng: {calculateTotal().totalQuantity || 0} sản phẩm</span>{' '}
                        {stepQuantity && stepQuantity > 1 && (
                            <span className="text-[#faad14] mt-1 text-xs">{`Cần mua theo lô ${stepQuantity} sản phẩm`} </span>
                        )}
                    </span>
                    <span className="text-base">Thành tiền: {formatPriceByUnit(calculateTotal().totalPrice || 0, 'CNY')}</span>
                </div>
                <Button
                    // sticky bottom-[-10px] left-[53%]
                    className="w-full mt-3 btn--blue"
                    onClick={handleAddToCart}
                    loading={loading}
                    size="large"
                    disabled={
                        Object.entries(quantity).filter(([skuId, qty]) => qty > 0).length < 1 ||
                        calculateTotal()?.totalQuantity < product?.minOrderQuantity
                    }
                >
                    Thêm sản phẩm vào giỏ hàng
                </Button>
            </div>
        </div>
    )
}

export default Product1688
