import { useCallback, useEffect, useState } from 'react'
import { Marketplace, parseMarketplaceUrl } from './handleUrl'
import AddProductCart from '../../../services/addProductCart.service'
import { Image, Modal, Spin } from 'antd'
import Product1688 from './Product1688'
import { LocalStore } from '../../../utils/LocalStore'

interface Props {
    openModal: boolean
    link: string
    handleModal: Function
    t: any
    loadData: Function
    handleClear: Function
}
const ModalProduct = (props: Props) => {
    const { openModal, handleModal, link, loadData, t, handleClear } = props
    const [product, setProduct] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const getDetailProductTaobao = (itemId: string) => {
        setLoading(true)
        AddProductCart.getDetailProductTaobao(itemId, (err: any, res: any) => {
            if (!err) {
                if (res) {
                    setProduct({ ...res, type: Marketplace.Taobao, id: itemId })
                    setLoading(false)
                } else {
                    setProduct(null)
                    setLoading(false)
                }
            } else {
                setProduct(null)
                setLoading(false)
            }
        })
    }

    const getDetailProduct1688 = (itemId: string) => {
        setLoading(true)
        AddProductCart.getDetailProduct1688(itemId, (err: any, res: any) => {
            if (!err) {
                if (res) {
                    if (
                        res?.productSaleInfo?.priceRangeList?.length > 0 &&
                        res.batchNumber === null &&
                        res.productSkuInfos !== null
                    ) {
                        const consignPrice = res?.productSaleInfo?.priceRangeList?.[0]?.price
                        const productSkuInfos = res?.productSkuInfos?.map((item: any) => {
                            return { ...item, consignPrice: consignPrice }
                        })
                        setProduct({
                            ...res,
                            productSkuInfos: productSkuInfos,
                            type: Marketplace.Alibaba,
                            id: itemId,
                        })
                    } else {
                        setProduct({
                            ...res,
                            type: Marketplace.Alibaba,
                            id: itemId,
                        })
                    }

                    setLoading(false)
                } else {
                    setProduct(null)
                    setLoading(false)
                }
            } else {
                setProduct(null)
                setLoading(false)
            }
        })
    }

    const getMobileUrlResponse = useCallback((marketPlace: string, link: string) => {
        const currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
        const loginSession = LocalStore.getInstance().read('loginSession') || {}
        const appApiUrl = process.env.REACT_APP_API_URL || window.location.hostname
        const marketplace = marketPlace === Marketplace.AlibabaMobile ? 'alibaba' : 'taobao'
        setLoading(true)
        fetch(`${appApiUrl}/customer/${marketplace}/get-link?shortLink=${link}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-tenant': currentProjectInfo.code,
                Authorization: 'Bearer ' + loginSession.id,
            },
        })
            .then(async res => {
                const data = await res.text()
                if (marketPlace === Marketplace.AlibabaMobile) {
                    const idMatch = data.match(/offerId=([^&]+)/) || data.match(/offerId%3D(\d+)/)
                    if (idMatch) {
                        getDetailProduct1688(idMatch[1])
                    } else {
                        setLoading(false)
                    }
                } else {
                    const urlMatch: any = data.match(/var url = ['"]([^'"]+)['"]/)
                    const idProdct = urlMatch[1].match(/id=([^&]+)/)
                    if (idProdct) {
                        getDetailProductTaobao(idProdct[1])
                    } else {
                        setLoading(false)
                    }
                }
            })
            .catch(err => {
                setLoading(false)
                console.error(err)
            })
    }, [])

    useEffect(() => {
        const marketplaceInfo = parseMarketplaceUrl(link)
        if (marketplaceInfo) {
            switch (marketplaceInfo.marketplace) {
                case Marketplace.Taobao:
                case Marketplace.Tmall:
                    getDetailProductTaobao(marketplaceInfo.productID!)
                    break
                case Marketplace.Alibaba:
                    getDetailProduct1688(marketplaceInfo.productID!)
                    break
                case Marketplace.AlibabaMobile:
                    getMobileUrlResponse(marketplaceInfo.marketplace, marketplaceInfo.originalUrl!)
                    break
                case Marketplace.TaobaoMobile:
                    getMobileUrlResponse(marketplaceInfo.marketplace, marketplaceInfo.originalUrl!)
                    break
                case Marketplace.TmallMobile:
                    getMobileUrlResponse(marketplaceInfo.marketplace, marketplaceInfo.originalUrl!)
                    break
            }
        }
    }, [link, getMobileUrlResponse])

    return (
        <Modal
            centered
            closable={!loading}
            maskClosable={!product ? true : false}
            className="modalAddToCart"
            bodyStyle={{ paddingTop: '32px', minHeight: !product && !loading ? 'auto' : '520px' }}
            title={null}
            open={openModal}
            footer={null}
            onCancel={() => {
                !loading && handleModal()
                // setIsModalAddCartOpen(false)
                // setDetailProductMp({})
                // handleClearParam()
            }}
            width={1000}
        >
            <Spin
                spinning={loading}
                className="h-[100vh] flex items-center justify-center"
            >
                {!product && !loading ? (
                    <>
                        <div className="text-center h-full">
                            <Image
                                style={{ width: '200px' }}
                                preview={false}
                                src={require('../../../resources/images/cartEmpty.png')}
                            />
                            <div className="mt-6 text-[#B1B1B1] text-center font-normal text-sm">
                                Sản phẩm không tồn tại hoặc đường dẫn bị sai! Vui lòng thử lại.
                            </div>
                        </div>
                    </>
                ) : null}
                {!loading && product && (
                    <Product1688
                        product={product}
                        loadData={loadData}
                        t={t}
                        link={link}
                        handleClear={handleClear}
                    />
                )}
            </Spin>
        </Modal>
    )
}

export default ModalProduct
