import PolimClient from '../utils/api/PolimClient'

export default class AddProductCart {
    static getDetailProductTaobao(id, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/taobao/fetch_global_product?itemId=${id}`,
                method: 'GET',
            },
            cb
        )
    }

    static trackingAddToCart() {
        PolimClient.request({
            endpoint: `tenants/current/tracking-add-to-cart`,
            method: 'POST',
        })
    }

    static getDetailProduct1688(id, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/alibaba/fetch_global_product?itemId=${id}`,
                method: 'GET',
            },
            cb
        )
    }

    static getMobileUrlResponse(marketPlace, url, cb) {
        PolimClient.request(
            {
                endpoint: `/customer/${marketPlace}/get-link?shortLink=${url}`,
                method: 'GET',
            },
            cb
        )
    }

    static addToCart(body, cb) {
        PolimClient.request(
            {
                endpoint: `customer/cart/skus`,
                body: body,
                method: 'POST',
            },
            cb
        )
    }
}
